.modal {
  @include glass-blur;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  overflow-y: auto;

  &__box {
    position: relative;
    margin: auto;
    background-color: rgba(255,255,255,.08);
    backdrop-filter: blur(5px);
    border: 1px solid transparent;
    box-shadow: 10px 10px 10px rgba(46, 54, 68, .09);
    background-clip: padding-box;
    border-radius: 20px;
    padding: 25px 25px 80px 25px;
    max-width: 800px;
    min-width: 600px; }

  &__title {
    font-size: 1.4em;
    margin-bottom: 25px;
 }    // text-transform: capitalize

  &__close {
    @include reset-button;
    position: absolute;
    top: -15px;
    right: -15px;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: rgba(255,255,255,.4);
    backdrop-filter: blur(5px);
    text-align: center;
    cursor: pointer;

    .MuiSvgIcon-root {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      display: block;
      pointer-events: none; } }

  &__attribution {
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    margin: 0 auto; } }

.modal--type--box {
    display: flex; }

@media screen and (max-width: $mobileBreakpoint2 + 'px') {
  .modal {

    &__box {
      max-width: 90%;
      min-width: 90%; }

    &__close {
      top: -10px;
      right: -10px; } } }

@media screen and (max-height: 609px) {
  .modal {
    padding: 30px 0; } }
