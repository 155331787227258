$headerHeight: 125px;

.escaperoom {
  $self: &;
  height: 100%;
  font-size: 1rem;
  overflow: hidden;
  transition: opacity 1.5s;

  &.view-transition--enter {
    opacity: 0; }

  &.view-transition--enter-active {
    opacity: 1; }

  &.view-transition--exit {
    opacity: 1; }

  &.view-transition--exit-active {
    opacity: 0; }

  &__header {
    display: flex;
    justify-content: space-between;
    padding: 25px;
    position: fixed;
    top: 0;
    left: 0;
    height: $headerHeight;
    width: 100%;

    &__meta {
      color: #fff;

      h1 {
        font-weight: 500;
        font-size: 1.4em;
        margin-bottom: 8px; }

      h2 {
        margin-bottom: 8px; } } }

  &__timer {
    color: #fff;
    text-align: center;

    &--completed &__value {
      color: $error; }

    &--finished &__value {
      color: #4ca64c; }

    li {
      display: inline-block;
      margin-right: 1em;

      &:last-child {
        margin-right: 0; } }

    &__value {
      font-weight: 700;
      font-size: 2.4em;
      margin-bottom: 8px; }

    &__label {
      font-weight: 300;
      text-transform: lowercase;
      font-size: 0.8em; } }

  &__clue {
    padding: 25px 10px;
    background: $primaryPurple;
    border-radius: 3px;
    margin-bottom: 25px; }

  &__challenge-container {
    padding: $headerHeight 0;
    max-width: 1000px;
    color: #fff;
    margin: 0 auto;
    min-height: 100vh;
    display: flex;
    align-items: center; }

  &__challenge {
    margin: 0 25px;

    &__content {
      margin-bottom: 25px;

      & > *:first-child {
        margin-top: 0; }

      p {
        margin-bottom: 25px;
        line-height: 1.5em;
        font-size: 1.1em; }

      img {
        display: block;
        max-width: 100%;
        margin: 25px 0;
        border-radius: 3px; } } }

  &__controls {
    position: fixed;
    bottom: 0;
    left: 0;
    // height: 35px
    display: flex;
    justify-content: space-between;
    width: 100%;
    transition: height 300ms; }

  &__button {

    &--positive {
      background: #9ee5b8; }

    &--unlock {
      flex-grow: 1;
      background-color: $primaryBlue;
      color: darken($primaryBlue, 40);
      position: absolute;
      right: -50px;
      bottom: -50px;
      display: flex;
      transition: transform 500ms, height 300ms, width 300ms, max-width 300ms;

      &:hover {
        background-color: darken($primaryBlue, 8); }

      .corner-button__button-inner {
        margin: auto;
        position: relative;
        right: 15px;
        bottom: 1em;
        transition: transform .6s; }

      &--exit {

        .corner-button__button-inner {
          transform: rotate(0deg); } }

      &--exit-active {

        .corner-button__button-inner {
          transform: rotate(90deg); } }

      &--enter {

        .corner-button__button-inner {
          transform: rotate(90deg); } }

      &--enter-active {

        .corner-button__button-inner {
          transform: none; } } }

    &--clue {
      // width: 300px
      // max-width: 35%
      background-color: $primaryPurple;
      color: darken($primaryPurple, 30);
      position: absolute;
      left: -50px;
      bottom: -50px;
      display: flex;

      &:hover {
        background-color: darken($primaryPurple, 8); }

      .corner-button__button-inner {
        margin: auto;
        position: relative;
        left: 25px;
        bottom: 1em; } } }

  &__questions {
    color: #fff;

    h3 {
      font-size: 1em;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0; } }

    &__row {
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0; }

      p {
        margin-top: 5px; } }

    label {
      color: #fff;
      font-size: 1.1em;
      display: block;
      margin-bottom: 10px; }

    &__nonleader {
      @include glass-blur;
      padding: 15px;
      border-radius: 3px;

      ol {
        list-style: decimal;
        margin-left: 20px; }

      li {
        font-size: 1.3em;
        color: #fff;
        font-weight: 500;
        margin-bottom: 10px; } } } }

.escaperoom__questions {
  transition: all 500ms; }

.escaperoom__challenge__content {
  transition: all 500ms; }

.escaperoom__challenge__content--exit {
  opacity: 1; }

.escaperoom__challenge__content--exit-active {
  opacity: 0;
  transform: translateX(-20px); }

.escaperoom__challenge__content--enter {
  opacity: 0;
  transform: translateX(20px); }

.escaperoom__challenge__content--enter-active {
  opacity: 1;
  transform: none; }

// ==============================

.escaperoom__questions {
  transition: all 600ms;
  transition-delay: 150ms; }

.escaperoom__questions--exit {
  opacity: 1; }

.escaperoom__questions--exit-active {
  opacity: 0;
  transform: translateX(-20px); }

.escaperoom__questions--enter {
  opacity: 0;
  transform: translateX(20px); }

.escaperoom__questions--enter-active {
  opacity: 1;
  transform: translateX(0px); }

@media screen and (max-width: $mobileBreakpoint2 + 'px') {
  .escaperoom {
    $self: &;

    &--unlock {

      #{$self}__button-inner {
        right: 10px;
        bottom: 25px; } }

    &--clue {

      #{$self}__button-inner {
        left: 25px;
        bottom: 25px; } } } }
