.rankings {
  color: #fff;
  // max-width: 800px
  // border-spacing: 10px
  // border-collapse: separate
  font-weight: 300;

  tr {
    border-bottom: 1px solid rgba(255,255,255,.4);

    &:last-of-type {
      border-bottom: none; }

    &.highlight {
      @include glass-blur;

      // td:first-of-type
      //   border-top-left-radius: 4px
      //   border-bottom-left-radius: 4px
      //
      // td:last-of-type
      //   border-top-right-radius: 4px
 } }      //   border-bottom-right-radius: 4px

  td {
    padding: 8px 30px;
    border-right: 1px solid rgba(255,255,255,.4);

    &:first-of-type {
      padding: 8px 10px 8px 10px; }

    &:last-of-type {
      border-right: none; } } }

@media screen and (max-width: $mobileBreakpoint2 + 'px') {
  .rankings {

    tr {
      border-bottom: none; }

    td {
      padding: 5px 12px; } } }
