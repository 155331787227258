.signoff {
  background: #000;
  height: 100%;
  padding: 30px;
  // clip-path: circle(125px at 95% 95%)
  transition: all .5s;

  &__reveal {
    clip-path: circle(100%); }

  &__logo {
    margin: 0 auto;
    display: block;
    width: 500px;
    max-width: 100%; }

  &__attribution {
    font-size: inherit !important; }

  &__rankings {
    margin: 30px auto; }

  &__text {
    padding: 0 30px;
    max-width: 1000px;
    margin: 0 auto;
    color: #fff;
    text-align: center;

    h2 {
      font-size: 2.4em;
      margin: 30px 0;
      line-height: 40px;
      // max-width: 75%
      font-weight: 300;
      color: rgba(255,255,255,.9); }

    p {
      font-size: 1.2em;
      line-height: 1.5em;
      margin: 30px 0;
      color: rgba(255,255,255,.8);

      a {
        color: inherit;
        font-weight: bold;
        text-decoration: none;
        transition: color .3s;

        &:hover {
          color: rgba(255,255,255,1); } } } }

  &__credits {
    font-size: 0.8em;

    p {
      margin: 15px 0; } }

  &__hr {
    margin: 30px auto;
    width: 33.3%;
    border-width: 1px;
    border-color: rgba(255,255,255,.4); } }

@media screen and (max-width: $mobileBreakpoint1 + 'px') {
  .signoff {

    &__text {
      font-size: 15px; } } }
