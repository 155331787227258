.invitation {
  color: #fff;
  // transition: opacity 1000ms
  // overflow: hidden
  $self: &;

  &.view-transition--exit {

    #{$self}__text {
      transition: all 1.3s; }

    #{$self}__counter-container {
      transition: all 1.3s; } }

  &.view-transition--exit-active {

    #{$self}__text {
      transform: translateY(15%);
      opacity: 0; }

    #{$self}__counter-container {
      transform: translateY(-15%);
      opacity: 0; } }

  &__text {
    position: relative;
    top: 10em;
    text-align: center;
    padding: 20px;

    h2 {
      font-size: 1.3em;
      margin-bottom: 20px; }

    h1 {
      font-size: 4em;
      margin-bottom: 20px;
      font-weight: bold;
 } }      // text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2)

  &__counter-container {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    width: 100%;
    overflow: hidden;
    // padding: 50px 0
    padding-top: 30px;
    // hacky height fix to adjust when there is no zoom info
    min-height: 33.3%; }

  &__countdown {
    margin-top: 20px;
    padding-bottom: 3em;
    max-width: 100%;
    transition: all 500ms;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;

    &--exit-active {
      animation-name: popOutDown;
      animation-duration: 500ms; }

    &--exit-done {
      opacity: 0; }

    &__wrapper {
      display: flex;
      justify-content: center;

      & li {
        margin-right: 40px;

        &:last-child {
          margin-right: 0; } } }

    &__value {
      font-size: 6em;
      font-weight: bold; }

    &__label {
      font-size: 1em;
      font-weight: bold;
      text-transform: lowercase; } }

  &__date {
    font-size: 1.2em;
    transition: all 300ms; }

  &__zoom-credentials {
    @include glass-blur;
    box-shadow: 10px 10px 40px rgba(0, 0, 0, .2);
    display: table;
    padding: 25px 5vmax 40px 5vmax;
    margin: 15px auto;
    border-radius: 20px;
    font-size: 1.1em;
    transform: translateY(100vh);
    position: relative;

    &--enter-active {
      animation-name: popInUp;
      animation-duration: 500ms;
      animation-delay: 1000ms; }

    &--enter-done {
      transform: translateY(0); }

    &--late, &--empty {
      line-height: 1.5em;
      padding: 15px;
      max-width: 500px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%); } }

  &__zoom-pulse {
    position: absolute;
    top: 20px;
    right: 15px; } }

@keyframes popInUp {
  0% {
    transform: translateY(120%); }
  50% {
    transform: translateY(-15px); }
  100% {
    transform: translateY(0); } }

@keyframes popOutDown {
  0% {
    transform: translateY(0);
    opacity: 1; }
  50% {
    transform: translateY(-15px);
    opacity: 1; }
  75% {
    transform: translateY(100vh);
    opacity: 1; }
  100% {
    transform: translateY(100vh);
    opacity: 0; } }

@media screen and (max-width: $mobileBreakpoint2 + 'px') {
  .invitation {
    font-size: 12px;

    &__counter-container {
      padding-bottom: 10px; }

    &__countdown {
      font-size: 9px;
      max-width: 400px;
      margin: 20px auto 0 auto;

      &__wrapper {
        padding: 0 30px;
        justify-content: space-between;

        & li {
          margin-right: 0; } } }

    &__date {
      margin-bottom: 20px;
      font-size: 1.8em; }

    &__zoom-credentials {
      width: 90%; } } }

@media screen and (max-width: $mobileBreakpoint1 + 'px') {
  .invitation {
    font-size: 11px;

    &__countdown__value {
      font-size: 5em; }

    &__zoom-credentials {
      font-size: 1.5em; } } }
