.zoom-credentials {

  &__credentials {
    text-align: center; }

  &__logo {
    max-width: 100px;
    display: block;
    margin: 0 auto; }

  &__meeting-info {
    margin-bottom: 10px;
    text-align: center;
    font-size: 1em;

    &:last-of-type {
      margin-bottom: 0; } } }
