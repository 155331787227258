$active: rgba(48,157,125, 1);
$neutral: #FFC800;
$inactive: #D62828;
$off: #666;

.pulse {
  position: relative;
  display: inline-block;
  z-index: 1;
  height: 1em;
  width: 1em;
  vertical-align: middle;
  margin: 0 0.5em;
  $self: &;

  &::after {
    content: "";
    height: 1em;
    width: 1em;
    background: $off;
    display: inline-block;
    border-radius: 1em;
    position: relative;
    height: 100%;
    width: 100%; }

  &::before {
    content: "";
    height: 75%;
    width: 75%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 100%;
    background-color: lighten($active, 20);
    transform-origin: center center;
    z-index: -1;
    backface-visibility: hidden; }

  &--type--active {

      &::after {
        background-color: $active; }

      &::before {
        animation: pulse-active 1.3s ease-in-out infinite;
        background-color: lighten($active, 20); } }

  &--type--neutral {

      &::after {
        background-color: $neutral; }

      &::before {
        background-color: $neutral;
        animation: pulse-neutral 1.3s ease-in-out infinite; } }

  &--type--inactive {

      &::after {
        background-color: $inactive; }

      &::before {
        animation: none !important; } }

  &--type--fast {

    &::before {
      animation: pulse-active 800ms ease-in-out infinite; } } }

@keyframes pulse-active {
  0% {
    height: 75%;
    width: 75%;
    background-color: transparentize($active, 0.7); }
  100% {
    height: 250%;
    width: 250%;
    background-color: transparentize($active, 1); } }

@keyframes pulse-neutral {
  0% {
    height: 100%;
    width: 100%;
    background-color: transparentize($neutral, 0.5); }
  100% {
    height: 200%;
    width: 200%;
    background-color: transparentize($neutral, 1); } }
