.game__screen__waiting {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  $self: &;

  &.view-transition--enter {

    #{$self}__inner {
      transition: all .4s;
      transform: translateX(15%);
      opacity: 0; } }

  &.view-transition--enter-active {

    #{$self}__inner {
      transform: translateX(0);
      opacity: 1; } }

  &.view-transition--exit {

    #{$self}__inner {
      transition: all .4s;
      transform: translateX(0);
      opacity: 1; } }

  &.view-transition--exit-active {

    #{$self}__inner {
      transform: translateX(-15%);
      opacity: 0; } }

  &__inner {
    text-align: center;
    padding: 0 35px;

    p {
      color: #fff;
      line-height: 1.5em; } }

  &__heading {
    font-size: 1.8em;
    font-weight: bold;
    margin-bottom: 10px; }

  &__subtext {
    font-size: 1em;
    margin-bottom: 20px !important; } }
