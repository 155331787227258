.wt-figure {
  margin-bottom: 25px;

  &__image {
    max-width: 100%;
    margin: 0 0 5px 0 !important; }

  &__caption {
    font-size: 12px;
    font-style: italic;

    a {
      color: inherit;
      text-decoration: none; } } }
