form.jw {

  input[type="text"],
  input[type="number"] {
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    outline: none;
    background-color: rgba( #fff, .7 );
    border-radius: 2px;
    padding: 10px;
    font-size: 1.1em;
    transition: background-color 300ms;
    font-weight: 500;
    margin: 0;

    &:focus, &:hover {
      background-color: #fff; }

    &.full-width {
      width: 100%; }

    &.error {
      border: 3px solid $error; } } }
