.info-toggle {
  @include reset-button;
  padding: 10px;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  transition: transform 300ms ease-in-out;
  z-index: 4;

  &:hover {
    transform: scale(1.1) rotate(-12deg); }

  .MuiSvgIcon-root {
    fill: #fff; } }
