.game__screen__enter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  $self: &;

  &.view-transition--enter {

    #{$self}__inner {
      transition: all .5s;
      transform: translateY(-15%);
      opacity: 0; } }

  &.view-transition--enter-active {

    #{$self}__inner {
      transform: translateY(0);
      opacity: 1; } }

  &.view-transition--exit {

    #{$self}__inner {
      transition: all .5s;
      transform: translateY(0);
      opacity: 1; } }

  &.view-transition--exit-active {

    #{$self}__inner {
      transform: translateY(-15%);
      opacity: 0; } }

  &__inner {
    text-align: center;
    padding: 0 35px;

    label {
      color: #fff;
      display: block;
      font-size: 1.3em;
      margin-bottom: 15px;
      text-transform: lowercase; } }

  &__hint {
    color: #fff;
    margin-bottom: 15px;
    text-transform: lowercase; }

  &__input-row {
    // display: flex
    // align-items: center
    // justify-content: center
    position: relative;

    input {
      font-size: 2em;
      text-align: center;
      display: table;
      // margin-right: 10px
      // position: relative
      margin: 0 auto !important;
      position: relative;
      z-index: 2;
      color: #9c27b0; }

    & .submit {
      // position: absolute
      position: relative;
      top: -3em;
      margin: 0 auto;
      height: 2.5em;
      width: 2.5em;
      text-align: center;
      outline: none;
      border: none;
      border-radius: 100%;
      display: block;
      background-color: #fff;
      z-index: 1;
      opacity: 0;
      transition: all 300ms;
      cursor: pointer;

      &--show {
        transform: translateY(4em);
        opacity: 1;

        .MuiSvgIcon-root {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto; } } } } }

@media screen and (max-width: $mobileBreakpoint2 + 'px') {
  .game__screen__enter {

    .submit {
      height: 4em;
      width: 4em;

      &--show {
        transform: translateY(4.2em); } } } }
